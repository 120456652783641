/* button types */
.textButton {
  padding-top: 8px;
  padding-bottom: 8px;
}

.outlineButton {
  border: solid;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.floatingButton {
  border-radius: 100px !important;
  box-shadow: 0px 4px 10px rgba(3, 18, 26, 0.15) !important;
}

.regularButton {
  border-radius: 6px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
}

/* styles */
.inner {
  opacity: 1;
  transition-property: opacity;
}

.innerFade {
  opacity: 0.5;
}

.hidden {
  visibility: hidden;
}

.buttonText {
  text-align: center;
  word-break: normal;
}

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  outline-offset: 0;
  user-select: none;
  white-space: nowrap;
}

/* sizes */
.small {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.medium {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.large {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}

/* variant */
.primary {
  background-color: #0194f3;
}

.secondary {
  background-color: #f7f9fa;
}

.main-cta {
  background-color: #ff5e1f;
}

.destructive-primary {
  background-color: #f4555a;
}

.destructive-secondary {
  background-color: #f7f9fa;
}

.outline-black {
  border-color: #354148;
}

.outline-white {
  border-color: #f2f3f3;
}

.outline-primary {
  border-color: #0194f3;
}

.floating-primary {
  background-color: #0194f3;
}

.floating-secondary {
  background-color: #f7f9fa;
}

.floating-main-cta {
  background-color: #ff5e1f;
}

.floating-outline-black {
  border-color: #354148;
}

.floating-outline-white {
  border-color: #f2f3f3;
}

/* hovered */
.root:hover {
  cursor: pointer;
}
.primary:hover {
  background-color: #007ce8;
}

.secondary:hover {
  background-color: #f2f3f3;
}

.main-cta:hover {
  background-color: #df440f;
}

.destructive-primary:hover {
  background-color: #ec3a3e;
}

.destructive-secondary:hover {
  background-color: #f2f3f3;
}

.outline-black:hover {
  border-color: #354148;
}

.outline-white:hover {
  border-color: #f2f3f3;
}

.outline-primary:hover {
  border-color: #0194f3;
}

.floating-primary:hover {
  background-color: #007ce8;
}

.floating-secondary:hover {
  background-color: #f2f3f3;
}

.floating-main-cta:hover {
  background-color: #ff5e1f;
}

.floating-outline-black:hover {
  border-color: #354148;
}

.floating-outline-white:hover {
  border-color: #f2f3f3;
}

/* disabled */
.primary:disabled {
  background-color: #f2f3f3;
}

.secondary:disabled {
  background-color: #f2f3f3;
}

.main-cta:disabled {
  background-color: #f2f3f3;
}

.destructive-primary:disabled {
  background-color: #f2f3f3;
}

.destructive-secondary:disabled {
  background-color: #f2f3f3;
}

.outline-black:disabled {
  border-color: #cdd0d1;
}

.outline-white:disabled {
  border-color: #cdd0d1;
}

.outline-primary:disabled {
  border-color: #687176;
}

.floating-primary:disabled {
  background-color: #f2f3f3;
}

.floating-secondary:disabled {
  background-color: #f2f3f3;
}

.floating-main-cta:disabled {
  background-color: #f2f3f3;
}

.floating-outline-black:disabled {
  border-color: #cdd0d1;
}

.floating-outline-white:disabled {
  border-color: #cdd0d1;
}
