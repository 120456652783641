.root {
  word-break: break-word;
}

/* Variant */
.hero {
  font-family: Godwit, System, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
  font-variant-ligatures: none;
}

.display {
  font-family: Godwit, System, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  font-variant-ligatures: none;
}

.headline {
  font-family: Godwit, System, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  font-variant-ligatures: none;
}

.title-1 {
  font-family: Godwit, System, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  font-variant-ligatures: none;
}

.title-2 {
  font-family: Godwit, System, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  font-variant-ligatures: none;
}

.title-3 {
  font-family: Godwit, System, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  font-variant-ligatures: none;
}

.ui-large {
  font-family: Godwit, System, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  font-variant-ligatures: none;
}

.ui-baseline {
  font-family: Godwit, System, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-variant-ligatures: none;
}

.ui-small {
  font-family: Godwit, System, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-variant-ligatures: none;
}

.ui-tiny {
  font-family: Godwit, System, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  font-variant-ligatures: none;
}

.ui-large-bold {
  font-family: Godwit, System, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  font-variant-ligatures: none;
}

.ui-baseline-bold {
  font-family: Godwit, System, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  font-variant-ligatures: none;
}

.ui-small-bold {
  font-family: Godwit, System, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  font-variant-ligatures: none;
}

.ui-tiny-bold {
  font-family: Godwit, System, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  font-variant-ligatures: none;
}

.caption-tiny {
  font-family: Godwit, System, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  font-variant-ligatures: none;
}

.caption-micro {
  font-family: Godwit, System, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 11px;
  line-height: 11px;
  font-weight: 400;
  font-variant-ligatures: none;
}

.button-large {
  font-family: Godwit, System, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
  font-variant-ligatures: none;
}

.button-medium {
  font-family: Godwit, System, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  font-variant-ligatures: none;
}

.button-small {
  font-family: Godwit, System, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  font-variant-ligatures: none;
}

/* Ink */
.black-primary {
  color: #03121a;
}

.black-secondary {
  color: #687176;
}

.black-muted {
  color: #cdd0d1;
}

.black-neutral {
  color: #354148;
}

.white-primary {
  color: #ffffff;
}

.white-secondary {
  color: #cdd0d1;
}

.white-muted {
  color: #687176;
}

.white-neutral {
  color: #f2f3f3;
}

.interactive {
  color: #0194f3;
}

.destructive {
  color: #f4555a;
}

.highlight {
  color: #0264c8;
}

.price {
  color: #ff5e1f;
}

.positive {
  color: #00875a;
}

.alert {
  color: #e7090e;
}

.warning {
  color: #b15400;
}
