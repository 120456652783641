.root {
  position: relative;
}
.indicator {
  height: 3px;
  width: 1px;
  bottom: 0px;
  background-color: #0194f3;
  transform-origin: left bottom;
}
.indicatorAnimated {
  transition-duration: 200ms;
  transition-property: transform, width;
}
.tabSpacer {
  margin-left: 16px;
}
